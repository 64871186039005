import React, { useEffect, useContext } from 'react';
import { Container, Icon, Typography } from '@mui/material';
// import createGAevent from 'helpers';
import { useLocation } from 'react-router-dom';
import PageNotFoundSvg from 'assets/pageNotFound.svg';

export default function PageNotFound() {
  const location = useLocation();

  // useEffect(() => {
  //   createGAevent('PageNotFound', `PAGE_NOT_FOUND-${location}`, 'PAGE_NOT_FOUND');
  // }, []);

  return (

      <Container style={{ backgroundColor: '', paddingTop: 20, justify: 'center' }}>
        <Typography sx={{ fontSize: 22, fontWeight: 'bold', pt: 5, pl: 3 }}>Page Not Found</Typography>
        <Typography sx={{ fontWeight: 300, fontSize: 16, pt: 5, pl: 3 }}>Oops! The requested URL {location.pathname} was not found on this server. Please try another page.</Typography>
        <Icon component="img" src={PageNotFoundSvg} alt="404" style={{ fontSize: 400, paddingLeft: 50 }} />
      </Container>
  );
}
