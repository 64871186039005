import { CodeHighlightNode, CodeNode } from '@lexical/code';
import { ListItemNode, ListNode } from '@lexical/list';
import { OverflowNode } from '@lexical/overflow';
import { HorizontalRuleNode } from '@lexical/react/LexicalHorizontalRuleNode';
import { LinkNode, AutoLinkNode } from '@lexical/link';
import { HeadingNode, QuoteNode } from '@lexical/rich-text';
import { CodeAnnotationNode } from './code-annotation-node/CodeAnnotationNode';
// import { ExcalidrawNode } from './ExcalidrawNode';
// import { StickyNode } from './StickyNode';

/***********************  
 
  //TODO: TO-DO-ITEM-ALL-NODES

  !!!!!!! WARNING !!!!!! 
  WHEN YOU ADD A NEW NODE HERE YOU MUST ADD IT createHeadlessCollaborationEditor.ts in the server.
  Otherwise, it will result in a crash and a SEV 2.
  Please add nodes first to the server and deploy. Then add nodes in the client.

************************/
const PlaygroundNodes = [
  HeadingNode,
  ListNode,
  ListItemNode,
  QuoteNode,
  CodeNode,
  CodeHighlightNode,
  OverflowNode,
  HorizontalRuleNode,
  LinkNode,
  AutoLinkNode,
  CodeAnnotationNode,
];

export default PlaygroundNodes;
