/* eslint-disable react/prop-types */
import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import FileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import { useState, useEffect } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { HTTP_OK_STATUS_CODE, PRIVATE_FOLDER_TYPE, apiDirLink, apiNotesLink } from 'sitevars';
import { DataRow, NoteModel } from 'interfaces/interfaces';
import AlertConfirmation from '../AlertConfirmation';
import DialogContentText from '@mui/material/DialogContentText';
import { fileTypesEnum } from 'strings';
import { useAPI } from 'context/ApiProvider';
import { AppContext } from 'context/context';
import { Typography } from '@mui/material';
import AddNewDocButton from 'components/sectionMain/buttons/AddNewDocButton';
import AddCircleSharpIcon from '@mui/icons-material/AddCircleSharp';
import { writeToErrorLog } from 'utils';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    height: '7vh',
    fontSize: 14,
    width: '50vw', //helps to keep the title text within the scope of tableCell
    overflow: 'hidden', //helps to keep the title text within the scope of tableCell
    textOverflow: 'ellipsis', //helps to keep the title text within the scope of tableCell
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: '',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
    borderShadow: 0,
  },
  'td, th': {
    border: 0,
    borderShadow: 0,
  },

  '&:hover': {
    backgroundColor: '#f5f5f5 !important',
  },
}));

const handleCellClick = (noteId: any) => {
  // //console.log( noteId);
};

export default function FilesTable({
  notesData,
  setNotesData,
  type,
}: {
  notesData: NoteModel[] | null;
  setNotesData: any;
  type: string | null;
}) {
  const navigate = useNavigate();
  const { API } = useAPI();
  const { state, dispatch } = React.useContext(AppContext);
  const [showActionId, setShowActionId] = useState('');
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [loadingData, setLoadingData] = useState<boolean>(true);
  const [loadedNoData, setLoadedNoData] = useState<boolean>(false);
  const [sortedData, setSortedData] = useState<NoteModel[] | null>(null);
  const [openDeleteAlert, setOpenDeleteAlert] = useState<boolean>(false);
  const [shouldDeleteFile, setShouldDeleteFile] = useState<boolean>(false);
  const [selectedNoteId, setSelectedNoteId] = useState<string | null>(null);
  const [dateTableLabel, setDateTableLabel] = useState<string>('');
  const open = Boolean(anchorEl);

  //console.log( '============== INSIDE FILES TABLE ================ with data: ', notesData);
  //console.log( 'with type: ', type);

  useEffect(() => {
    // This function will be called whenever `selectedFolderId` changes
    // //console.log(
    //   `INSIDE USE EFFECT ~~~~~~~~~~~~ Selected folder ID changed to ${state.selectedFolderName} WITH TYPE ${type}`
    // );
    if (type == fileTypesEnum.FOLDER) {
      // //console.log( 'in type folder !!!!');
      const data = notesData?.filter((note) => note.folder.id == state.selectedFolderId);
      if (data) {
        setSortedData(data);
      }
    } else {
      setSortedData(notesData);
    }
  }, [state.selectedFolderId]);

  useEffect(() => {
    //console.log( 'in use effect with data: ', notesData);

    if (loadingData && notesData) {
      if (type == fileTypesEnum.RECENT) {
        //console.log( 'in type recent');
        const data = notesData.sort(function (a, b) {
          return new Date(b.updatedAt).valueOf() - new Date(a.updatedAt).valueOf();
        });

        if (data && data.length > 0) {
          setSortedData(data);
          setDateTableLabel('Modified');
          setLoadingData(false);
        } else {
          setLoadedNoData(true);
          setLoadingData(false);
        }
      } else if (type == fileTypesEnum.PRIVATE) {
        //console.log( 'in type private');
        let data = notesData.filter((note) => note.folder.type == PRIVATE_FOLDER_TYPE);
        data = data.sort(function (a, b) {
          return new Date(b.updatedAt).valueOf() - new Date(a.updatedAt).valueOf();
        });

        if (data && data.length > 0) {
          setSortedData(data);
          setDateTableLabel('Modified');
          setLoadingData(false);
        } else {
          setLoadedNoData(true);
          setLoadingData(false);
        }
      } else if (type == fileTypesEnum.FAVOURITES) {
        // no sorting required for favoutires
        //console.log( 'in type fav');

        let data = notesData.filter((note) => note.userFavorites.length > 0);

        data = data.sort(function (a, b) {
          return new Date(b.updatedAt).valueOf() - new Date(a.updatedAt).valueOf();
        });

        if (data && data.length > 0) {
          setSortedData(data);
          setDateTableLabel('Modified');
          setLoadingData(false);
          //console.log( 'notesData: ', data);
        } else {
          setLoadedNoData(true);
          setLoadingData(false);
        }
      } else if (type == fileTypesEnum.FOLDER) {
        //console.log( 'in type folder');
        let data = notesData.filter((note) => note.folder.id == state.selectedFolderId);

        // //console.log( 'selceted folder data: ', data);
        data = data.sort(function (a, b) {
          return new Date(b.updatedAt).valueOf() - new Date(a.updatedAt).valueOf();
        });

        if (data && data.length > 0) {
          setSortedData(data);
          setDateTableLabel('Modified');
          setLoadingData(false);
        } else {
          setLoadedNoData(true);
          setLoadingData(false);
        }
      }
    }
  }, []);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = async (event: any) => {
    event.preventDefault();
    setSelectedNoteId(event.currentTarget.id);
    setOpenDeleteAlert(true);
    handleClose();
  };

  //TODO: used in 2 places
  const deleteNote = async (noteId: string | null) => {
    if (noteId) {
      try {
        const selectedNote = sortedData?.filter((note) => note.id == noteId);
        if (!selectedNote || selectedNote.length <= 0 || !selectedNote[0]) {
          //TODO: send error
          //TODO: Add a toast to inform that the note could not be deleted for some reason
          setAnchorEl(null);
          return;
        }
        const res = await API.delete(`${apiDirLink}/f/${selectedNote[0].folder.id}/n/${noteId}/delete`);
        if (res.status == HTTP_OK_STATUS_CODE) {
          const updatedNotes = sortedData?.filter((item) => {
            return item.id !== noteId;
          });
          if (updatedNotes) {
            setSortedData(updatedNotes);
          }
        } else {
          //TODO: Add a toast to inform that the note could not be deleted for some reason
        }
        setAnchorEl(null); // in both cases close the note menu
      } catch (e) {
        //console.log( 'Error while deleting note!! ', e);
        writeToErrorLog(e, 'deleting a note ', state.userEmail);
      }
    }
  };

  useEffect(() => {
    if (shouldDeleteFile) {
      deleteNote(selectedNoteId);
      setShouldDeleteFile(false);
    }
  }, [shouldDeleteFile]);

  const handleOpenInNewTab = (noteId, folderId) => {
    window.open(`/f/${folderId}/n/${noteId}`, '_blank');
    handleClose();
  };

  const getDate = (dateString: any) => {
    const date = new Date(dateString);
    return formatDate(date);
  };

  function formatDate(d: Date) {
    return new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'short',
      day: '2-digit',
    }).format(d);
  }

  function ShouldDeleteText() {
    const selectedNote = sortedData?.find((item) => {
      return item.id == selectedNoteId || '';
    });

    return selectedNote ? (
      <DialogContentText>
        Would you like to delete <strong>{selectedNote.title}</strong>?
      </DialogContentText>
    ) : (
      <DialogContentText>Would you like to delete this document?</DialogContentText>
    );
  }

  const handleAddToFavourites = async (event: any) => {
    handleClose();
    const noteId = event.currentTarget.id;
    try {
      await API.post(`${apiNotesLink}/addToFavourites`, {
        noteId,
      });
    } catch (e) {
      //TODO: Add toast to inform something went wrong
      //console.log( 'Error while adding to favourites! ', e);
      writeToErrorLog(e, 'adding to favourites', state.userEmail);
    }
  };

  return (
    <div>
      {selectedNoteId && openDeleteAlert && (
        <AlertConfirmation
          text={<ShouldDeleteText />}
          open={openDeleteAlert}
          setOpen={setOpenDeleteAlert}
          status={shouldDeleteFile}
          confirmationFunction={setShouldDeleteFile}
          confirmationText="Yes, delete"
        />
      )}
      {!loadingData && sortedData && sortedData.length > 0 && !loadedNoData ? (
        <TableContainer
          style={{
            height: '90vh',
            width: '100%',
            overflow: 'auto',
          }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell>Document name</StyledTableCell>
                <StyledTableCell align="center">Folder</StyledTableCell>
                <StyledTableCell align="center">{dateTableLabel}</StyledTableCell>
                <StyledTableCell align="center"></StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedData?.map((row: NoteModel) => (
                <StyledTableRow
                  key={row.id}
                  onMouseEnter={() => {
                    setShowActionId(row.id); // set id here
                  }}
                  onMouseLeave={() => setShowActionId('')}
                >
                  <StyledTableCell
                    align="center"
                    onClick={() => navigate(`/f/${row.folder.id}/n/${row.id}`)}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      flexDirection: 'row',
                      justifyContent: 'left',
                      minWidth: '50vw',
                      maxWidth: '50vw',
                    }}
                  >
                    <FileOutlinedIcon sx={{ fontSize: 18, paddingRight: 1, color: '#555555' }} />
                    {/* <Icon
                      component="img"
                      src={file}
                      alt="Icon"
                      style={{ fontSize: 14, color: '#555555', paddingRight: 1 }}
                    /> */}
                    <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                      {row.title}
                    </div>
                  </StyledTableCell>
                  <StyledTableCell align="center" onClick={handleCellClick}>
                    <span>{row.folder.name}</span>
                  </StyledTableCell>
                  <StyledTableCell align="center" onClick={handleCellClick}>
                    {getDate(row.updatedAt)}
                  </StyledTableCell>
                  <StyledTableCell align="center" onClick={handleCellClick}>
                    {row.id === showActionId ? (
                      <div>
                        <Button onClick={handleClick}>
                          <MoreHorizIcon sx={{ color: 'lightgrey' }} />
                        </Button>

                        <Menu
                          id="basic-menu"
                          anchorEl={anchorEl}
                          open={open}
                          onClose={handleClose}
                          MenuListProps={{
                            'aria-labelledby': 'basic-button',
                          }}
                        >
                          <MenuItem id={row.id.toString()} onClick={() => handleOpenInNewTab(row.id, row.folder.id)}>
                            Open in New Tab
                          </MenuItem>
                          <MenuItem id={row.id.toString()} onClick={handleDelete}>
                            Delete Document
                          </MenuItem>
                          {!fileTypesEnum.FAVOURITES && (
                            <MenuItem id={row.id.toString()} onClick={handleAddToFavourites}>
                              Add to Favoutites
                            </MenuItem>
                          )}
                        </Menu>
                      </div>
                    ) : (
                      <Button>
                        <MoreHorizIcon sx={{ color: 'white' }} />
                      </Button>
                    )}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <></>
        // <Typography>Loading...</Typography> //TODO: Loader needs to go away if there is no data returned
      )}
      {loadedNoData && (
        <>
          <Typography sx={{ padding: 2, fontWeight: 300, fontSize: 14 }}>
            Currently, there are no documents in this folder. Start by creating a new document.
            <AddNewDocButton disableTip={true} icon={<AddCircleSharpIcon sx={{ fontSize: 20, color: '#404040' }} />} />
          </Typography>
        </>
      )}
    </div>
  );
}
