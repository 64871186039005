import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import FileCopySharpIcon from '@mui/icons-material/FileCopySharp';
import SourceSharpIcon from '@mui/icons-material/SourceSharp';
import StarSharpIcon from '@mui/icons-material/StarSharp';
import FoldersSidebar from '../foldersSection/FoldersSidebar';
import { AppContext } from 'context/context';
import { useContext } from 'react';
import { Types } from 'context/reducers';
import { fileTypesEnum, strings } from 'strings';
import { SECTION_ALL_DOCS, SECTION_FAVOURITES, SECTION_PRIVATE } from 'sitevars';
import { useNavigate } from 'react-router-dom';

export default function HomeSection() {
  const { state, dispatch } = useContext(AppContext);
  const navigate = useNavigate();

  const homeItems = [
    {
      text: strings.allTitle,
      icon: <FileCopySharpIcon sx={{ fontSize: 20, color: '#404040' }} />,
      section: SECTION_ALL_DOCS,
      fileType: fileTypesEnum.RECENT,
      path: '/all',
    },
    {
      text: strings.privateTitle,
      icon: <SourceSharpIcon sx={{ fontSize: 20, color: '#404040' }} />,
      section: SECTION_PRIVATE,
      fileType: fileTypesEnum.PRIVATE,
      path: '/private',
    },
    {
      text: strings.favouritesTitle,
      icon: <StarSharpIcon sx={{ fontSize: 20, color: '#404040' }} />,
      section: SECTION_FAVOURITES,
      fileType: fileTypesEnum.FAVOURITES,
      path: '/favourites',
    },
  ];

  return (
    <div
      style={{
        backgroundColor: 'white',
        paddingTop: 15,
        paddingLeft: 3,
        borderTopLeftRadius: '15px',
        borderBottomLeftRadius: '20px',
        height: '99vh',
      }}
    >
      <Typography
        style={{
          paddingTop: 0,
          paddingLeft: 5,
          display: 'flex',
          alignItems: 'center',
          fontSize: 20,
          fontWeight: 'bold',
        }}
      >
        {strings.mainSectionSidebarTitle}
      </Typography>

      <List>
        {homeItems?.map((item) => (
          <ListItem
            key={item.text}
            style={{ padding: 0, marginLeft: 4, backgroundColor: '', cursor: 'pointer' }}
            onClick={() => {
              navigate(item.path);
              dispatch({
                type: Types.SelectedFolderType,
                payload: {
                  selectedFolderId: null,
                  selectedFolderName: item.text,
                  selectedFolderType: item.fileType,
                },
              });
            }}
          >
            <ListItemIcon
              style={{
                padding: 0,
                margin: 5,
                backgroundColor: '',
                minWidth: 25,
              }}
            >
              {item.icon}
            </ListItemIcon>
            <Typography style={{ fontWeight: '300', paddingTop: 2 }}>{item.text}</Typography>
          </ListItem>
        ))}
      </List>

      <FoldersSidebar />
    </div>
  );
}
