import React, { useEffect } from 'react';
import { AppContext } from 'context/context';
import HomeSection from './HomeSection';
import NotificationSection from './NotificationSection';
import { useAPI } from 'context/ApiProvider';
import { HTTP_OK_STATUS_CODE, apiUserLink } from 'sitevars';
import { MemebershipTypes } from 'strings';
import { Types } from 'context/reducers';
import { writeToErrorLog } from 'utils';

const HomeSidebar = () => {
  const { state, dispatch } = React.useContext(AppContext);
  const { API } = useAPI();

  useEffect(() => {
    getUserMembership(0);
  }, []);

  const getUserMembership = async (retry: number) => {
    if (retry >= 0 && retry <= 2) {
      const result = await API.get(`${apiUserLink}/membership`);
      if (result && result.status == HTTP_OK_STATUS_CODE) {
        const membership = result.data.membership;
        if (membership != null && membership != '') {
          dispatch({
            type: Types.MembershipType,
            payload: {
              membership,
            },
          });
        } else {
          //console.log( 'Membership was null or empty');
        }
      } else {
        //console.log( `Error while getting membership for retry number ${retry}`);
      }
    } else {
      //TODO: SEV!!!
      //console.log( `Error - couldn't get membership after ${retry} retries`);
    }
  };

  return (
    <div
      style={{
        backgroundColor: '#0d87e9',
        width: 198,
        borderRight: '1px solid lightgrey',
      }}
    >
      {state.sectionNav == 'home' && <HomeSection />}
      {state.sectionNav == 'notifications' && <NotificationSection />}
    </div>
  );
};

export default HomeSidebar;
