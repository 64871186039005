import { withAuthenticationRequired } from '@auth0/auth0-react';
import React from 'react';
import Loading from './Loading';

// eslint-disable-next-line react/prop-types
const AuthenticationGuard = ({ component: Component }) => {
  const AuthenticatedComponent = withAuthenticationRequired(Component, {
    onRedirecting: () => (
      <div className="page-layout">
        <Loading />
      </div>
    ),
  });

  return <AuthenticatedComponent />;
};

export default AuthenticationGuard;
