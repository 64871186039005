import React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import { Typography } from '@mui/material';
import Icon from '@mui/material/Icon';
import discord from 'assets/discord.svg';

const DiscordButton = () => {
  return (
    <>
      <ListItemButton id="discord-button" onClick={() => window.open('https://discord.gg/xJZbFXAcF6', '_blank')}>
        <Icon component="img" src={discord} alt="Icon" style={{ fontSize: 20, color: '#A9A9A9', paddingRight: 5 }} />
        <Typography sx={{ fontWeight: 300 }}>Join our community</Typography>
      </ListItemButton>
    </>
  );
};

export default DiscordButton;
