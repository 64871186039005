import React, { createContext, useState, useContext } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { apiBaseLink, auth0Vars } from 'sitevars';
import axios from 'axios';
import type { AxiosInstance } from 'axios';
import { writeToErrorLog } from 'utils';

export interface ApiProviderOptions {
  children?: React.ReactNode;
}

export interface ApiContextInterface {
  API: AxiosInstance;
}

const ApiContext = createContext<ApiContextInterface | undefined>(undefined);

export const useAPI = () => {
  const context = useContext(ApiContext);
  if (!context) {
    throw new Error('You forgot to wrap your component in <APIProvider>.');
  }
  return context;
};

const ApiProvider = (opts: ApiProviderOptions): JSX.Element => {
  const { getAccessTokenSilently, user } = useAuth0();

  // //console.log( 'inside API Provider ...');

  const [API] = useState(() => {
    const API = axios.create({
      headers: {
        'Content-Type': 'application/json',
      },
      baseURL: apiBaseLink,
    });

    API.interceptors.request.use(async (config) => {

      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: auth0Vars.audience,
            scope: 'openid profile email offline_access',
          },
        });

        // TODO: Handle errors
        config.headers ??= {};
        config.headers.Authorization = `Bearer ${accessToken}`;

        //console.log( 'about to return config ...');
        return config;
      } catch (e) {
          writeToErrorLog(e, 'create API interceptor', user?.email);
          config.headers = {};
          config.headers.Authorization = ''
          return config;
      }
    });

    return API;
  });

  return <ApiContext.Provider value={{ API }}>{opts.children}</ApiContext.Provider>;
};

export default ApiProvider;
