import React, { createContext, useReducer } from 'react';
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import { SECTION_ALL_DOCS, apiBaseLink, auth0Vars } from 'sitevars';
import APIProvider from './ApiProvider';

import {
  combinedReducers,
  SectionNavActions,
  SelectedFolderActions,
  RefetchNotesAction,
  MembershipAction,
  UserEmailAction,
} from './reducers';
import { MemebershipTypes, fileTypesEnum, strings } from 'strings';

type InitialStateType = {
  sectionNav: string;
  //folder related state
  selectedFolderId: string | null;
  selectedFolderName: string | null;
  selectedFolderType: string | null;
  refetchNotes: boolean;
  membership: string;
  userEmail: string | null | undefined;
};

const initialState = {
  sectionNav: 'home',
  //folder related state
  selectedFolderId: null,
  selectedFolderName: strings.allTitle,
  selectedFolderType: fileTypesEnum.RECENT,
  refetchNotes: false,
  membership: MemebershipTypes.INDIVIDUAL,
  userEmail: null,
};

type ProviderProps = {
  children: React.ReactNode;
};

const AppContext = createContext<{
  state: InitialStateType;
  dispatch: React.Dispatch<
    SectionNavActions | SelectedFolderActions | RefetchNotesAction | MembershipAction | UserEmailAction
  >;
}>({
  state: initialState,
  dispatch: () => null,
});

// // ({ sectionNav }: InitialStateType, action: SectionNavActions | AnotherAction
// const mainReducer = ({ sectionNav }: InitialStateType, action: SectionNavActions ) => ({
//   sectionNav: sectionNavReducer(sectionNav, action as SectionNavActions),
// });

const AppProvider = ({ children }: ProviderProps) => {
  // const navigate = useNavigate();
  const [state, dispatch] = useReducer(combinedReducers, initialState);

  return (
    <APIProvider>
      <AppContext.Provider value={{ state, dispatch }}>{children}</AppContext.Provider>
    </APIProvider>
  );
};

export { AppContext, AppProvider };
