import React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import { Typography } from '@mui/material';
import Icon from '@mui/material/Icon';
import vscode from 'assets/vscode.svg';

const VSCodeButton = () => {
  return (
    <>
      <ListItemButton
        id="vscode-button"
        onClick={() =>
          window.open('https://marketplace.visualstudio.com/items?itemName=codexplainai.codexplain', '_blank')
        }
      >
        <Icon component="img" src={vscode} alt="Icon" style={{ fontSize: 20, color: '#A9A9A9', paddingRight: 5 }} />
        <Typography sx={{ fontWeight: 300 }}>Download IDE plugin</Typography>
      </ListItemButton>
    </>
  );
};

export default VSCodeButton;
