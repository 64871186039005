import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import { NotificationTypeEnum } from 'strings';
import { Alert } from '@mui/material';

type Props = {
  type: (typeof NotificationTypeEnum)[keyof typeof NotificationTypeEnum];
  message: string;
  open: boolean;
  close: any;
  position?: string;
  time?: number;
};

const ToastAction = (props: Props) => {
  const DEFAULT_TIME = 3500;
  let vertical: 'top' | 'bottom' = 'bottom';
  let horizontal: 'right' | 'center' = 'right';
  if (props.position == 'top-center') {
    vertical = 'top';
    horizontal = 'center';
  }

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={props.open}
        onClose={props.close}
        autoHideDuration={props.time ? props.time : DEFAULT_TIME}
        key={vertical + horizontal}
      >
        <Alert severity={props.type}>{props.message}</Alert>
      </Snackbar>
    </>
  );
};

export default ToastAction;
