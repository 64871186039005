import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import HomeIcon from '@mui/icons-material/Home';
import NotificationsIcon from '@mui/icons-material/Notifications';
import SearchModal from 'components/SearchModal';
import ProfileModal from '../ProfileModal';
import { useNavigate } from 'react-router-dom';
import { AppContext } from 'context/context';
import { Types } from 'context/reducers';
import { useContext } from 'react';
import { routes } from '../../sitevars'; //TODO: fix proper path
import RepositoriesButton from '../sectionMain/buttons/RepositoriesButton';
import HomeSidebar from './HomeSidebar';
import MenuSidebar from './MenuSidebar';

const drawerWidth = 60;

export default function Sidebar() {
  return (
    <div style={{ flex: 1, display: 'flex' }}>
      <MenuSidebar />
      <HomeSidebar />
    </div>
  );
}
