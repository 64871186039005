import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Autocomplete, Button, TextField, Tooltip, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { useNavigate } from 'react-router-dom';
import AlertConfirmation from '../AlertConfirmation';
import DialogContentText from '@mui/material/DialogContentText';
import { useAPI } from 'context/ApiProvider';
import { HTTP_OK_STATUS_CODE, RECENT_FOLDER_TYPE, apiDirLink } from 'sitevars';
import { FolderModel } from 'interfaces/interfaces';
import { AppContext } from 'context/context';
import { Types } from 'context/reducers';
import ToastAction from 'components/ToastAction';
import { MemebershipTypes, NotificationTypeEnum, fileTypesEnum, strings } from 'strings';
import PremiumButton from 'components/buttons/PremiumButton';
import { writeToErrorLog } from 'utils';

interface FoldersIconMenuProps {
  folder: FolderModel;
  setEditingItemId: any;
  setEditTitleValue: any;
  allFolders: FolderModel[];
  setAllFolders: any;
  setFolderInEdit: any;
}

export default function FoldersIconMenu({
  folder,
  setEditingItemId,
  setEditTitleValue,
  allFolders,
  setAllFolders,
  setFolderInEdit,
}: FoldersIconMenuProps) {
  const navigate = useNavigate();
  const { API } = useAPI();
  const { state, dispatch } = React.useContext(AppContext);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openNewModal, setOpenNewModal] = useState<boolean>(false);
  const [shouldCreateFolder, setShouldCreateFolder] = useState<boolean>(false);
  const [openDeleteAlert, setOpenDeleteAlert] = useState<boolean>(false);
  const [shouldDeleteFile, setShouldDeleteFile] = useState<boolean>(false);
  const [openAddMembersAlert, setOpenAddMembersAlert] = useState<boolean>(false);
  const [selectedEmails, setSelectedEmails] = useState<string | null>('');
  const open = Boolean(anchorEl);
  //notification toast vars
  const [notification, setNotification] = useState<boolean>(false);
  const [notificationText, setNotificationText] = useState<string>('');
  const [notificationType, setNotificationType] = useState<
    typeof NotificationTypeEnum[keyof typeof NotificationTypeEnum]
  >(NotificationTypeEnum.INFO);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const deleteFolder = async () => {
    //console.log( 'delete folder');
    try {
      const res = await API.delete(`${apiDirLink}/f/${folder.id}/delete`);
      if (res && res.status == HTTP_OK_STATUS_CODE) {
        // if the selected folder was opened in FileTabs, then close it & set the selected FileTabs folder to recent
        if (state.selectedFolderId == folder.id) {
          navigate('/all');
          dispatch({
            type: Types.SelectedFolderType,
            payload: {
              selectedFolderId: null,
              selectedFolderName: strings.allTitle,
              selectedFolderType: fileTypesEnum.RECENT,
            },
          });
        } else {
          dispatch({
            type: Types.RefetchNotesType,
            payload: {
              refetchNotes: true,
            },
          });
        }

        const updatedFolders = allFolders.filter((fol) => fol.id != folder.id);
        setAllFolders(updatedFolders);

        setNotification(true);
        setNotificationText('The folder was deleted.');
        setNotificationType(NotificationTypeEnum.SUCCESS);
      } else {
        setNotification(true);
        setNotificationText('The folder was not deleted. Please try again');
        setNotificationType(NotificationTypeEnum.ERROR);
      }
    } catch (e) {
      //console.log( 'Error while deleting a folder! ', e);
      setNotification(true);
      setNotificationText('The folder was not deleted. Please try again');
      setNotificationType(NotificationTypeEnum.ERROR);
      writeToErrorLog(e, 'deleting a folder', state.userEmail);
    }
  };

  const handleDelete = async (event: any) => {
    event.preventDefault();
    setOpenDeleteAlert(true);
    handleClose();
  };

  const handleRename = async (event: any) => {
    //console.log( 'rename');
    setAnchorEl(null);
    setEditingItemId(folder.id);
    setEditTitleValue(folder.name);
    setFolderInEdit(folder);
  };

  function ShouldDeleteText() {
    return (
      <DialogContentText>
        Would you like to delete <strong>{folder.name}</strong> folder? This will delete all documents in the folder.
      </DialogContentText>
    );
  }

  const handleAddMembers = async () => {
    //console.log( 'handleAddMembers');
    setOpenAddMembersAlert(true);
    handleClose();
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setSelectedEmails(newValue);
  };

  function ShouldAddMembers() {
    const isPremiumMember = state.membership != MemebershipTypes.INDIVIDUAL;
    return (
      <>
        {!isPremiumMember && (
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <PremiumButton text="Premium feauture" tooltipText="Only premium members can share folders" />
          </div>
        )}

        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Typography sx={{ paddingTop: 2, paddingBottom: 2, color: '#404040' }}>
            To share <span style={{ color: '#0d87e9' }}>{folder.name}</span> folder with other people, add their emails
            separated by a comma below:
          </Typography>
          {isPremiumMember ? (
            <TextField
              id="outlined-multiline-flexible"
              label="Emails"
              placeholder="joe@doe.com, jane@smith.com"
              autoFocus
              variant="standard"
              InputLabelProps={{
                shrink: true,
              }}
              value={selectedEmails}
              onChange={handleEmailChange}
            />
          ) : (
            <TextField
              id="outlined-multiline-flexible"
              label="Emails"
              placeholder="joe@doe.com, jane@smith.com"
              autoFocus
              variant="standard"
              inputProps={{
                readOnly: true,
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          <Typography sx={{ paddingBottom: 2, paddingTop: 2, fontSize: 16, color: '#404040' }}>
            This will grant new members full permissions to all documents in the{' '}
            <span style={{ fontWeight: 600 }}>{folder.name}</span> folder including viewing, editing and deleting
            documents.
          </Typography>
        </div>
      </>
    );
  }

  const addMembers = async () => {
    //console.log( 'inside add members with selectedEmails', selectedEmails);
    setOpenAddMembersAlert(false);
    setSelectedEmails('');

    const invited = selectedEmails?.split(',').map((email) => email.trim());
    if (invited != null && invited != undefined && invited.length > 0) {
      try {
        const invitedResult = await API.post(`${apiDirLink}/f/${folder.id}/invite`, {
          invited,
        });

        if (invitedResult && invitedResult.status == HTTP_OK_STATUS_CODE) {
          setNotification(true);
          setNotificationText('New members added to the folder!');
          setNotificationType(NotificationTypeEnum.SUCCESS);
        } else {
          handleNewMembersErrorNotification();
        }
      } catch (e) {
        //console.log( 'Error in adding new team members! ', e);
        handleNewMembersErrorNotification();
        writeToErrorLog(e, 'adding new team members', state.userEmail);
      }
    } else {
      handleNewMembersErrorNotification();
    }
  };

  const handleNewMembersErrorNotification = () => {
    setNotification(true);
    setNotificationText('New members were not added to the folder. Please try again.');
    setNotificationType(NotificationTypeEnum.ERROR);
  };

  const handleCloseNotification = () => {
    setNotification(false);
    setNotificationText('');
    setNotificationType(NotificationTypeEnum.INFO);
  };

  return (
    <div>
      {openAddMembersAlert && (
        <AlertConfirmation
          text={<ShouldAddMembers />}
          open={openAddMembersAlert}
          setOpen={setOpenAddMembersAlert}
          status={false}
          confirmationFunction={addMembers}
          confirmationText="Share"
          cancelationText="Cancel"
          canUndoAction={true}
        />
      )}

      {notification && (
        <ToastAction
          message={notificationText}
          type={notificationType}
          open={notification}
          close={handleCloseNotification}
        />
      )}

      {openDeleteAlert && (
        <AlertConfirmation
          text={<ShouldDeleteText />}
          open={openDeleteAlert}
          setOpen={setOpenDeleteAlert}
          status={shouldDeleteFile}
          confirmationFunction={deleteFolder}
          confirmationText="Yes, delete"
        />
      )}
      <IconButton
        id="note-icon-menu-button"
        disableRipple
        style={{ color: '#2196f3' }}
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <MoreHorizIcon sx={{ color: 'lightgrey' }} />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={handleAddMembers}>Add members</MenuItem>
        <MenuItem onClick={handleRename}>Rename folder</MenuItem>
        <MenuItem onClick={handleDelete}>Delete folder</MenuItem>
      </Menu>
    </div>
  );
}
