// import { useRef, useState } from 'react';
// import { AutoFocusPlugin } from '@lexical/react/LexicalAutoFocusPlugin';
// import { ClearEditorPlugin } from '@lexical/react/LexicalClearEditorPlugin';

import FloatingLinkEditorPlugin from './plugins/FloatingLinkEditorPlugin';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import ContentEditable from './ui/ContentEditable';
import Placeholder from './ui/Placeholder';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import 'index.css';
import { useState } from 'react';

export default function Editor(): JSX.Element {
  const text = 'Enter some text...';
  const placeholder = <Placeholder>{text}</Placeholder>;

  const [floatingAnchorElem, setFloatingAnchorElem] = useState<HTMLDivElement | null>(null);

  const onRef = (_floatingAnchorElem: HTMLDivElement) => {
    if (_floatingAnchorElem !== null) {
      setFloatingAnchorElem(_floatingAnchorElem);
    }
  };

  return (
    <>
      <div className="editor-container">
        {/* <AutoFocusPlugin /> // this plugin breaks scrollToTop on first editor load*/}
        {/* <ClearEditorPlugin /> // remove if you don't know what to use it for ;) */}
        <div className="editor-inner">
          <RichTextPlugin
            contentEditable={
              <div>
                <div ref={onRef}>
                  <ContentEditable className="editor-input" />
                </div>
              </div>
            }
            placeholder={placeholder}
            ErrorBoundary={LexicalErrorBoundary}
          />
          {floatingAnchorElem != null && <FloatingLinkEditorPlugin anchorElem={floatingAnchorElem} />}
        </div>
      </div>
    </>
  );
}
