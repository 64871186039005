import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItem from '@mui/material/ListItem';
import { Button, Tooltip } from '@mui/material';
import GitHubIcon from '@mui/icons-material/GitHub';
import { githubAuthentication, routes, webAppLink, webRoutes } from 'sitevars';

const style = {
  position: 'absolute' as const,
  top: '40%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  padding: 5,
  width: 500,
  bgcolor: 'background.paper',
  boxShadow: 10,
};

const RepositoriesButton = () => {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Tooltip title="Connect to GitHub" placement="right" arrow>
        <ListItem onClick={handleOpen} style={{ backgroundColor: '', cursor: 'pointer' }}>
          <ListItemIcon
            style={{
              backgroundColor: '',
              minWidth: 25,
            }}
          >
            <GitHubIcon sx={{ color: '#fff' }} />
          </ListItemIcon>
        </ListItem>
      </Tooltip>
      <Modal aria-labelledby="transition-modal-repos" open={open} onClose={handleClose} closeAfterTransition>
        <Fade in={open}>
          <Box sx={style}>
            <Typography sx={{ fontWeight: 300, fontSize: 18, borderBottom: '1px solid #ccc', paddingBottom: 1 }}>
              Connect repositories
            </Typography>
            <Typography sx={{ paddingTop: 2, paddingBottom: 2, fontWeight: 300 }}>
              To enable documentation for a code repository, authenticate with GitHub below.
            </Typography>
            <Typography sx={{ fontWeight: 300 }}>- Only read access required.</Typography>
            <Typography sx={{ fontWeight: 300 }}>- Codexplain never stores code on its servers.</Typography>
            <Typography sx={{ fontWeight: 300 }}>- Codexplain cannot make any modifications to your code.</Typography>

            <Button
              sx={{ marginTop: 2, marginBottom: 2, color: '#0d87e9' }}
              variant="outlined"
              onClick={() => window.open(githubAuthentication, '_blank')}
            >
              Connect with Github
            </Button>

            <Typography
              sx={{ fontWeight: 300, cursor: 'pointer' }}
              onClick={() => window.open(`${webAppLink}${webRoutes.security}`, '_blank')}
            >
              Learn about our <span style={{ color: '#0d87e9' }}>Privacy & Security</span>
            </Typography>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default RepositoriesButton;
