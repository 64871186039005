import { useAuth0 } from '@auth0/auth0-react';
import { Button } from '@mui/material';

type Props = {
  text?: string;
};

const LoginButton = ({ text = 'Log in' }: Props) => {
  const { loginWithRedirect } = useAuth0();

  return (
    <>
      <Button
        style={{ color: 'white', backgroundColor: '#0d87e9' }}
        onClick={() =>
          loginWithRedirect({
            authorizationParams: {
              redirect_uri: window.location.origin,
            },
          })
        }
      >
        {text}
      </Button>
    </>
  );
};

export default LoginButton;
