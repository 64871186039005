import React from 'react';
import { useState, useEffect, useContext } from 'react';
import Box from '@mui/material/Box';
import FilesTable from './FilesTable';
import { NoteModel } from 'interfaces/interfaces';
import { PRIVATE_FOLDER_TYPE, apiDirLink, apiNotesLink } from 'sitevars';
import Typography from '@mui/material/Typography';
import AddNewDocButton from 'components/sectionMain/buttons/AddNewDocButton';
import { strings, fileTypesEnum } from 'strings';
import { useAPI } from 'context/ApiProvider';
import { AppContext } from 'context/context';
import { Types } from 'context/reducers';
import { useAuth0 } from '@auth0/auth0-react';
import { writeToErrorLog } from 'utils';

const FilteredNotesSection = ({ notes }: { notes: NoteModel[] | null }) => {
  const [notesData, setNotesData] = useState<NoteModel[] | null>(null);
  const [loading, setLoading] = useState(true);
  const { API } = useAPI();
  const { user } = useAuth0();
  const { state, dispatch } = useContext(AppContext);

  useEffect(() => {
    setLoading(true);
    getNotes();
    if (state.refetchNotes) {
      // if this method is called because refetchNotes is set to true, then we need to set it back to false
      dispatch({
        type: Types.RefetchNotesType,
        payload: {
          refetchNotes: false,
        },
      });
    }
  }, [state.selectedFolderId, state.selectedFolderType, state.refetchNotes]);

  useEffect(() => {
    if (loading) {
      getNotes();
      getUserEmail();
    }
  }, []);

  const getUserEmail = () => {
    if (!state.userEmail && user?.email) {
      dispatch({
        type: Types.UserEmailType,
        payload: {
          userEmail: user.email,
        },
      });
    }
  };

  const getNotes = async () => {
    writeToErrorLog('error test', 'getting notes is getNotes', state.userEmail);
    try {
      if (notes == null) {
        const { data } = await API.get(`${apiNotesLink}/all`);
        setNotesData(data);
        setLoading(false);
      } else {
        setNotesData(notes);
        setLoading(false);
      }
    } catch (e) {
      //console.log( 'Error while getting notes is getNotes! ', e);
      writeToErrorLog(e, 'getting notes is getNotes', state.userEmail);
    }
  };

  return (
    <>
      <>
        <div
          style={{
            borderBottom: '1px solid',
            borderColor: 'divider',
            width: '80vw', //if not provided on refresh the add new note flickers ???
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginTop: 15,
              borderBottom: '1px solid lightgrey',
              justifyContent: 'space-between',
              backgroundColor: '',
            }}
          >
            <Typography style={{ fontSize: 20, fontWeight: 'bold', marginLeft: 10 }}>
              {state.selectedFolderName}
            </Typography>

            <AddNewDocButton />
          </div>
          {!loading && <FilesTable notesData={notesData} setNotesData={setNotesData} type={state.selectedFolderType} />}
        </div>
      </>
    </>
  );
};

export default FilteredNotesSection;
