import * as React from 'react';
import { Routes, Route } from 'react-router-dom';
import { routes } from './sitevars'; //TODO: fix proper path
import Login from 'pages/account/Login';
import LexicalCodeNote from 'pages/LexicalCodeNote';
import { AppProvider } from './context/context';
import FilteredNotesSection from 'components/sectionMain/FilteredNotesSection';
import Sidebar from 'components/sectionNav/Sidebar';
import HomeSection from 'components/sectionNav/HomeSection';
import NotificationSection from 'components/sectionNav/NotificationSection';
import { AppContext } from 'context/context';
import { useAuth0 } from '@auth0/auth0-react';
import AuthenticationGuard from 'components/AuthenticaationGuard';
import { useEffect, useState } from 'react';
import Loading from 'components/Loading';
import Home from 'pages/account/Home';
import { useLocation } from 'react-router-dom';
import PageNotFound from 'pages/PageNotFound';

export default function App(): JSX.Element {
  const { isAuthenticated, isLoading } = useAuth0();
  const location = useLocation();

  if (isLoading) {
    return <Loading />;
  }

  return (
    <React.Fragment>
      <AppProvider>
        <div style={{ display: 'flex', height: '100vh' }}>
          {isAuthenticated && location.pathname != routes.login && (
            <div style={{ position: 'fixed', height: '100vh' }}>
              <Sidebar />
            </div>
          )}

          <div // id="container"
            style={{
              marginLeft: '259px', // home sidebar 198 + menu side bar 60
              display: 'flex',
              background: '',
              width: '100vw',
            }}
          >
            <Routes>
              <Route path={routes.login} element={<Home />} />
              <Route path={'/f/:folderId/n/:noteId'} element={<AuthenticationGuard component={LexicalCodeNote} />} />

              {isAuthenticated && !isLoading ? (
                <Route path="/" element={<AuthenticationGuard component={FilteredNotesSection} />} />
              ) : (
                <Route path="/" element={<Home />} />
              )}

              {/* Folder types */}
              <Route path="/all" element={<FilteredNotesSection notes={null} key="recent" />} />
              <Route path="/private" element={<AuthenticationGuard component={FilteredNotesSection} />} />
              <Route path="/favourites" element={<AuthenticationGuard component={FilteredNotesSection} />} />
              <Route path="/folder/:id" element={<AuthenticationGuard component={FilteredNotesSection} />} />

              <Route path="*" element={<PageNotFound />} />
            </Routes>
          </div>
        </div>
      </AppProvider>
    </React.Fragment>
  );
}
