export const strings = {
  mainSectionSidebarTitle: 'Home',
  allTitle: 'All documents',
  favouritesTitle: 'Favourites',
  privateTitle: 'Private',
};

export const fileTypesEnum = {
  RECENT: 'recent',
  PRIVATE: 'private',
  FAVOURITES: 'favourites',
  FOLDER: 'selected_folder',
};

export const MemebershipTypes = {
  INDIVIDUAL: 'INDIVIDUAL',
  BUSINESS: 'BUSINESS',
  ENTERPRISE: 'ENTERPRISE',
};

//note: this values MUST be the same as MUI6 aalert types
export const NotificationTypeEnum = {
  SUCCESS: 'success',
  ERROR: 'error',
  INFO: 'info',
  WARN: 'warning',
} as const;
