/* eslint-disable react/prop-types */
import * as React from 'react';
import { useState } from 'react';
import { Radio, FormControl, RadioGroup, FormControlLabel } from '@mui/material';
import { FolderModel } from 'interfaces/interfaces';

interface FoldersCheckListProps {
  folders: FolderModel[] | null;
  moveToFolderId: string | null;
  setMoveToFolderId: (id: string) => void;
}

const FoldersCheckList = ({ folders, moveToFolderId, setMoveToFolderId }: FoldersCheckListProps) => {
  const handleFolderSelection = (event) => {
    const selectedValue = event.target.value;
    //console.log( 'selectedValue for movetofolder: ', selectedValue);
    setMoveToFolderId(event.target.value);
  };

  return (
    <>
      <FormControl>
        <RadioGroup name="radio-buttons-group-for-folders" value={moveToFolderId} onChange={handleFolderSelection}>
          {folders?.map((item) => (
            <>
              <FormControlLabel value={item.id} control={<Radio />} label={item.name} sx={{ color: '#404040' }} />
            </>
          ))}
        </RadioGroup>
      </FormControl>
    </>
  );
};

export default FoldersCheckList;
