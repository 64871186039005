import { useState, useEffect } from 'react';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import Editor from 'lexical/Editor';
import AllNodes from 'lexical/nodes/AllNodes';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import PlaygroundEditorTheme from 'lexical/themes/PlaygroundEditorTheme';
import ToolbarPlugin from 'lexical/plugins/ToolbarPlugin';
import HorizontalRulePlugin from 'lexical/plugins/HorizontalRulePlugin';
import CheckListPlugin from 'lexical/plugins/CheckListPlugin';
import LexicalClickableLinkPlugin from '@lexical/react/LexicalClickableLinkPlugin';
import LexicalAutoLinkPlugin from 'lexical/plugins/AutoLinkPlugin';

// import ExcalidrawPlugin from 'lexical/plugins/ExcalidrawPlugin';
import TreeViewPlugin from 'lexical/plugins/TreeViewPlugin';

import CodeHighlightPlugin from 'lexical/plugins/CodeHighlightPlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import 'index.css';
import { CollaborationPlugin } from '@lexical/react/LexicalCollaborationPlugin';
import { WebsocketProvider } from 'y-websocket';
import * as Y from 'yjs';
import { yjsWebsocketLink } from 'sitevars';
import { useAuth0 } from '@auth0/auth0-react';
import { Provider } from '@lexical/yjs';

export default function LexicalEditor({
  ydocUUID,
  shouldUpdateYdoc,
}: // onChange,
{
  ydocUUID: string | undefined;
  shouldUpdateYdoc: boolean;
  // onChange: any;
}): JSX.Element {
  const { isLoading, user } = useAuth0();
  const [provider, setProvider] = useState<Provider>();

  useEffect(() => {
    const doc = new Y.Doc();
    const provider = new WebsocketProvider(yjsWebsocketLink, ydocUUID as string, doc);
    setProvider(provider as any);
  }, []);

  const initialConfig = {
    // NOTE: This is critical for collaboration plugin to set editor state to null. It
    // would indicate that the editor should not try to set any default state
    // (not even empty one), and let collaboration plugin do it instead
    editorState: null,
    namespace: 'Playground',
    nodes: [...AllNodes],
    editable: true,
    onError: (error: unknown) => {
      throw error;
    },
    theme: PlaygroundEditorTheme,
  };

  return (
    // TODO: need proper way to specify the left padding required for sidebar across most of the pages
    <div style={{ backgroundColor: '', paddingLeft: 0 }}>
      <LexicalComposer initialConfig={initialConfig}>
        <div className="toolbar-container">
          <ToolbarPlugin />
          <CheckListPlugin />
          <ListPlugin />
          {/* <CodeAnnotationPlugin /> */}
          <CodeHighlightPlugin />
          <HorizontalRulePlugin />
          <LexicalAutoLinkPlugin />
          {/* <ExcalidrawPlugin /> */}
          <HistoryPlugin />
          {/* <TreeViewPlugin /> */}
        </div>
        <div className="editor-shell">
          <Editor />
          {provider && (
            <CollaborationPlugin
              id={ydocUUID as string}
              username={user?.name}
              providerFactory={(id, yjsDocMap) => {
                yjsDocMap.set(id, (provider as any).doc);
                return provider;
              }}
              initialEditorState={null}
              shouldBootstrap={true}
            />
          )}
          <LinkPlugin />
          <LexicalClickableLinkPlugin />
        </div>
      </LexicalComposer>
    </div>
  );
}
