import { FolderModel } from 'interfaces/interfaces';

type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
      }
    : {
        type: Key;
        payload: M[Key];
      };
};

export enum Types {
  SectionNavType = 'SET_NAV_SECTION',
  SelectedFolderType = 'SET_SELECT_FOLDER',
  RefetchNotesType = 'REFETCH_NOTES',
  MembershipType = 'MEMBERSHIP',
  UserEmailType = 'USER_EMAIL',
}

// --------------- REFETCH NOTES ---------------
export type RefetchNotesType = {
  refetchNotes: boolean;
};

type RefetchNotesPayload = {
  [Types.RefetchNotesType]: {
    refetchNotes: boolean;
  };
};

export type RefetchNotesAction = ActionMap<RefetchNotesPayload>[keyof ActionMap<RefetchNotesPayload>];

// --------------- MEMBERSHIP ---------------
export type MembershipType = {
  membership: string;
};

type MembershipPayload = {
  [Types.MembershipType]: {
    membership: string;
  };
};

export type MembershipAction = ActionMap<MembershipPayload>[keyof ActionMap<MembershipPayload>];

// --------------- MEMBERSHIP ---------------
export type UserEmailType = {
  userEmail: string | undefined | null;
};

type UserEmailPayload = {
  [Types.UserEmailType]: {
    userEmail: string | undefined | null;
  };
};

export type UserEmailAction = ActionMap<UserEmailPayload>[keyof ActionMap<UserEmailPayload>];

// --------------- SECTION NAV ---------------
export type SectionNavType = {
  sectionNav: string;
};

type SectionNavPayload = {
  [Types.SectionNavType]: {
    sectionNav: string;
  };
};

export type SectionNavActions = ActionMap<SectionNavPayload>[keyof ActionMap<SectionNavPayload>];

// ------------------ SELECTED FOLDER ------------------
export type SelectedFolderType = {
  [Types.SelectedFolderType]: {
    selectedFolderId: string | null;
    selectedFolderName: string | null;
    selectedFolderType: string | null;
  };
};

type SelectedFolderPayload = {
  [Types.SelectedFolderType]: {
    selectedFolderId: string | null;
    selectedFolderName: string | null;
    selectedFolderType: string | null;
  };
};

export type SelectedFolderActions = ActionMap<SelectedFolderPayload>[keyof ActionMap<SelectedFolderPayload>];

// --------------------------------

export const combinedReducers = (state: any, action: any) => {
  switch (action.type) {
    case Types.SectionNavType:
      return {
        ...state,
        sectionNav: action.payload.sectionNav,
      };

    case Types.SelectedFolderType:
      return {
        ...state,
        selectedFolderId: action.payload.selectedFolderId,
        selectedFolderName: action.payload.selectedFolderName,
        selectedFolderType: action.payload.selectedFolderType,
      };

    case Types.RefetchNotesType:
      return {
        ...state,
        refetchNotes: action.payload.refetchNotes,
      };

    case Types.MembershipType:
      return {
        ...state,
        membership: action.payload.membership,
      };

    case Types.UserEmailType:
      return {
        ...state,
        userEmail: action.payload.userEmail,
      };

    default:
      return state;
  }
};
