import React from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ListItemButton from '@mui/material/ListItemButton';
import { webAppLink, webRoutes } from 'sitevars';
import { Typography } from '@mui/material';

const HelpButton = () => {
  return (
    <>
      <ListItemButton id="info-button" onClick={() => window.open(`${webAppLink}${webRoutes.faq}`, '_blank')}>
        <InfoOutlinedIcon sx={{ fontSize: 20, color: '#404040', paddingRight: 1 }} />
        <Typography sx={{ fontWeight: 300 }}>Help and FAQ</Typography>
      </ListItemButton>
    </>
  );
};

export default HelpButton;
