import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Avatar from '@mui/material/Avatar';
import { useAuth0 } from '@auth0/auth0-react';
import IconButton from '@mui/material/IconButton';
import { Tooltip, Typography } from '@mui/material';
import { LogoutButton } from 'pages/account/LogoutButton';
import HelpButton from './buttons/HelpButton';
import PrivacyButton from './buttons/PrivacyButton';
import DiscordButton from './buttons/DiscordButton';
import VSCodeButton from './buttons/VSCodeButton';
import PremiumButton from './buttons/PremiumButton';
import { AppContext } from 'context/context';
import { useEffect } from 'react';
import GettingStartedButton from './buttons/GettingStartedButton';

const style = {
  position: 'absolute' as const,
  top: '40%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 300,
  padding: 5,
  bgcolor: 'background.paper',
  boxShadow: 10,
  paddingBottom: 5,
};

export default function ProfileModal() {
  const [open, setOpen] = React.useState(false);
  const { user } = useAuth0();
  const { state, dispatch } = React.useContext(AppContext);
  const [userName, setUserName] = React.useState<string>('');

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const membershipType = state.membership.toLowerCase();

  useEffect(() => {
    if (user != null && user != undefined) {
      if (user.name?.includes(' ') && !user.name.includes('@')) {
        setUserName(user.name);
      } else if (user.nickname && !user.nickname.includes('@')) {
        setUserName(user.nickname);
      }
    }
  }, []);

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <>
        <Tooltip title="Profile" placement="right" arrow>
          <IconButton
            id="profile-button"
            disableRipple
            style={{
              color: '#2196f3',
              fontSize: '10px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            onClick={handleOpen}
          >
            {user ? (
              <Avatar alt={user.name} src={user.picture} style={{ width: '30px', height: '30px' }} />
            ) : (
              <Avatar alt="user avatar" style={{ width: '30px', height: '30px' }} />
            )}
          </IconButton>
        </Tooltip>

        <Modal
          aria-labelledby="transition-modal-title-avatar"
          aria-describedby="transition-modal-description-avatar"
          open={open}
          onClose={handleClose}
          closeAfterTransition
        >
          <Fade in={open}>
            <Box sx={style}>
              <Typography sx={{ fontWeight: 300, fontSize: 20, borderBottom: '1px solid #ccc', paddingBottom: 1 }}>
                User Profile
              </Typography>
              {user ? (
                <>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'left',
                      alignItems: 'center',
                      paddingTop: 20,
                      paddingBottom: 20,
                    }}
                  >
                    <Avatar alt={user.name} src={user.picture} sx={{ width: 50, height: 50 }} />
                    <div>
                      {userName != '' && (
                        <Typography sx={{ paddingLeft: 2, fontWeight: 400, fontSize: 16 }}>{userName}</Typography>
                      )}
                      <Typography sx={{ paddingLeft: 2, fontWeight: 400, fontSize: 16 }}>{user.email}</Typography>
                    </div>
                  </div>

                  {membershipType && (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'left',
                        alignItems: 'center',
                        paddingTop: 0,
                        paddingBottom: 20,
                        backgroundColor: '',
                      }}
                    >
                      <Typography sx={{ paddingLeft: 0, fontWeight: 300, fontSize: 16, paddingRight: 1 }}>
                        Membership:
                      </Typography>
                      <PremiumButton text={membershipType} />
                    </div>
                  )}

                  <div>
                    <Typography sx={{ fontWeight: 300, fontSize: 18 }}>Actions</Typography>
                    <HelpButton />
                    <GettingStartedButton />
                    <PrivacyButton />
                    <VSCodeButton />
                    <DiscordButton />
                    <LogoutButton />
                  </div>
                </>
              ) : (
                <Typography sx={{ fontWeight: 300, fontSize: 16 }}>
                  Please log in to access user information.
                </Typography>
              )}
            </Box>
          </Fade>
        </Modal>
      </>
    </div>
  );
}
