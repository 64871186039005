import * as React from 'react';
import Button from '@mui/material/Button';
import MenuIcon from '@mui/icons-material/DriveFileRenameOutline';
import { useNavigate } from 'react-router-dom';
import { apiNotesLink } from 'sitevars';
import { useAPI } from 'context/ApiProvider';
import { Tooltip } from '@mui/material';
import { writeToErrorLog } from 'utils';
import { AppContext } from 'context/context';
// import { IconType } from '@mui/icons-material';

export default function AddNewDocButton({ icon, disableTip }: { icon?: any; disableTip?: boolean }) {
  const navigate = useNavigate();
  const { API } = useAPI();
  const { state, dispatch } = React.useContext(AppContext);

  //TODO: used in 2 places
  const handleNewDocument = async () => {
    //console.log( 'inside handleNewDocument');
    try {
      const {
        data: { id, folderId },
      } = await API.post(`${apiNotesLink}/create`);
      //console.log( 'created new noteId: ', id);

      navigate(`/f/${folderId}/n/${id}`);
    } catch (e) {
      //console.log( 'Error while adding a new doc from button! ', e);
      writeToErrorLog(e, 'adding a new doc from button', state.userEmail);
    }
  };

  return (
    <>
      {disableTip == true ? (
        <Button
          id="add-new-document-button"
          sx={{ textTransform: 'none' }}
          style={{
            color: '#2196f3',
            marginRight: 40,
          }}
          onClick={handleNewDocument}
        >
          {/* Create new doc */}
          {icon ? icon : <MenuIcon />}
        </Button>
      ) : (
        <Tooltip title="Create new doc">
          <Button
            id="add-new-document-button"
            sx={{ textTransform: 'none' }}
            style={{
              color: '#2196f3',
              marginRight: 40,
            }}
            onClick={handleNewDocument}
          >
            {/* Create new doc */}
            {icon ? icon : <MenuIcon />}
          </Button>
        </Tooltip>
      )}
    </>
  );
}
