import React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import { useAuth0 } from '@auth0/auth0-react';
import { Typography } from '@mui/material';
import ExitToAppSharpIcon from '@mui/icons-material/ExitToAppSharp';
import { useNavigate } from 'react-router-dom';

export const LogoutButton = () => {
  const { logout } = useAuth0();
  const navigate = useNavigate();

  const handleLogout = () => {
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  };

  return (
    <ListItemButton id="logout-button" onClick={handleLogout}>
      <ExitToAppSharpIcon sx={{ fontSize: 20, color: '#404040', paddingRight: 1 }} />

      <Typography sx={{ fontWeight: 300 }}>Logout</Typography>
    </ListItemButton>
  );
};
