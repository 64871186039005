import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import LexicalEditor from './LexicalEditor';
import TextField from '@mui/material/TextField';
import StarBorderRoundedIcon from '@mui/icons-material/StarBorderRounded';
import StarFillRoundedIcon from '@mui/icons-material/StarRounded';
import { useParams } from 'react-router-dom';
import { apiNotesLink, apiDirLink, PRIVATE_FOLDER_TYPE, HTTP_OK_STATUS_CODE } from 'sitevars';
import Sidebar from 'components/sectionNav/Sidebar';
import NoteBurgerMenu from '../components/NoteBurgerMenu';
import ShareButton from '../components/buttons/ShareButton';
import AlertConfirmation from '../components/AlertConfirmation';
import DialogContentText from '@mui/material/DialogContentText';
import IconButton from '@mui/material/IconButton';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import Loading from 'components/Loading';
import { useAPI } from 'context/ApiProvider';
import { FolderModel } from 'interfaces/interfaces';
import FoldersCheckList from '../components/FoldersCheckList';
import WarningAmberSharpIcon from '@mui/icons-material/WarningAmberSharp';
import { ListItem, ListItemIcon } from '@mui/material';
import ToastAction from 'components/ToastAction';
import { NotificationTypeEnum } from 'strings';
import { writeToErrorLog } from 'utils';
import { AppContext } from 'context/context';

export const LexicalCodeNote = () => {
  const params = useParams();
  const { API } = useAPI();
  const [folders, setFolders] = useState<FolderModel[] | null>(null);
  const [loadingFolders, setLoadingFolders] = useState(true);
  const [moveToFolderId, setMoveToFolderId] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [noteTitle, setNoteTitle] = useState<string | null>(null);
  const [noteId, setNoteId] = useState<string | null>(null);
  const [folderId, setFolderId] = useState<string | null>(null);
  const [isNoteTitleFocused, setIsNoteTitledFocused] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [shouldMoveToFolder, setShouldMoveToFolder] = useState<boolean>(false);
  const [shouldTryShare, setShouldTryShare] = useState<boolean>(false);
  const [isPrivate, setIsPrivate] = useState<boolean | null>(null);
  const [openShareAlert, setOpenShareAlert] = useState<boolean>(false);
  const [isFavourite, setIsFavourite] = useState<boolean>(false);
  const [ydocUUID, setYDocUUID] = useState<string | undefined>();
  // this is used to control the yjs focus on the editor and not on other components
  const [shouldUpdateYdoc, setShouldUpdateYdoc] = useState<boolean>(true);
  const noteTitlePlaceholder = 'Untitled';
  //notifications vars
  const [notificationText, setNotificationText] = useState<string>('');
  const [notificationType, setNotificationType] = useState<
    (typeof NotificationTypeEnum)[keyof typeof NotificationTypeEnum]
  >(NotificationTypeEnum.INFO);
  const [notification, setNotification] = useState<boolean>(false);
  const { state, dispatch } = React.useContext(AppContext);

  const getNote = async () => {
    try {
      const {
        data: { note, isFavourite },
      } = await API.get(`${apiDirLink}/f/${params.folderId}/n/${params.noteId}`);

      setNoteId(note.id);
      setFolderId(note.folderId);
      setIsFavourite(isFavourite);
      setNoteTitle(note.title);
      setYDocUUID(note.yDocId);
      setLoading(false);
      setIsPrivate(note.folder.type == PRIVATE_FOLDER_TYPE);
    } catch (e) {
      setHasError(true);
      //console.log( 'Error while getting note in lexical code note! ', e);
      writeToErrorLog(e, 'getting note in lexical code note', state.userEmail);
    }
  };

  const handleAddToFavourites = async () => {
    try {
      //TODO this should add to fav only for the current user
      const res = await API.post(`${apiNotesLink}/n/${noteId}/favourites/add`);
      if (res && res.status == HTTP_OK_STATUS_CODE) {
        setIsFavourite(true);
        setShouldUpdateYdoc(true);
        setNotification(true);
        setNotificationText(`Document was added to Favourites!`);
        setNotificationType(NotificationTypeEnum.SUCCESS);
      } else {
        setNotification(true);
        setNotificationText(`Document was not added to Favourites. Please try again.`);
        setNotificationType(NotificationTypeEnum.ERROR);
      }
    } catch (e) {
      //console.log( 'Error while adding to favourites in lexical code note! ', e);
      setShouldUpdateYdoc(true);
      setNotification(true);
      setNotificationText(`Document was not added to Favourites. Please try again.`);
      setNotificationType(NotificationTypeEnum.ERROR);
      writeToErrorLog(e, 'adding to favourites in lexical code note', state.userEmail);
    }
  };

  const handleRemoveFromFavourites = async () => {
    try {
      const res = await API.delete(`${apiNotesLink}/n/${noteId}/favourites/remove`);
      if (res && res.status == HTTP_OK_STATUS_CODE) {
        setIsFavourite(false);
        setShouldUpdateYdoc(true);
        setNotification(true);
        setNotificationText(`Document was removed from Favourites!`);
        setNotificationType(NotificationTypeEnum.SUCCESS);
      } else {
        setNotification(true);
        setNotificationText(`Document was not removed from Favourites. Please try again.`);
        setNotificationType(NotificationTypeEnum.ERROR);
      }
    } catch (e) {
      //TODO: log error
      //console.log( 'Error whole removing from favourites in lexical code note! ', e);
      setShouldUpdateYdoc(true);
      setNotification(true);
      setNotificationText(`Document was not removed from Favourites. Please try again.`);
      setNotificationType(NotificationTypeEnum.ERROR);
      writeToErrorLog(e, 'adding to remove in lexical code note', state.userEmail);
    }
  };

  useEffect(() => {
    getNote();
    getFolders();
  }, []);

  useEffect(() => {
    if (noteTitle && !isNoteTitleFocused) {
      saveUpdatedNoteTitle(noteTitle);
      setShouldUpdateYdoc(true);
    }
  }, [isNoteTitleFocused]);

  const saveUpdatedNoteTitle = async (noteTitle: string) => {
    try {
      await API.post(`${apiDirLink}/f/${folderId}/n/${noteId}/update`, {
        updates: [
          {
            action: 'title',
            data: noteTitle,
          },
        ],
      });
    } catch (e) {
      //console.log( 'Error while save updated note title in lexical code note! ', e);
      writeToErrorLog(e, 'save updated note title in lexical code note', state.userEmail);
    }
  };

  const handleTitleClick = () => {
    setShouldUpdateYdoc(false);
    setIsNoteTitledFocused(true);
  };

  useEffect(() => {
    if (shouldTryShare) {
      setOpenShareAlert(true);
      setShouldTryShare(false);
      setShouldUpdateYdoc(true); //make false when click on share button
    }
  }, [shouldTryShare]);

  const getFolders = async () => {
    const { data } = await API.get(`${apiDirLink}/f`);
    //console.log( 'folders: ', data);
    //TODO: check data is present and no errors
    setFolders(data);
    setLoadingFolders(false);
  };

  const moveNoteToFolder = async () => {
    const res = await API.post(`${apiDirLink}/f/${moveToFolderId}/n/${noteId}/mv`);
    if (res.status == HTTP_OK_STATUS_CODE) {
      setFolderId(moveToFolderId);
      const mvFolder = folders?.find((fold) => fold.id == moveToFolderId);
      if (mvFolder) {
        setIsPrivate(mvFolder.type == PRIVATE_FOLDER_TYPE);
        setNotification(true);
        setNotificationText(`Document was successfully moved to ${mvFolder.name}`);
        setNotificationType(NotificationTypeEnum.SUCCESS);
      } else {
        //TODO: error log that we moved the note but folder is not found
        setNotification(true);
        setNotificationText('Document was not successfully moved. Please try again.');
        setNotificationType(NotificationTypeEnum.ERROR);
      }
    } else {
      setNotification(true);
      setNotificationText('Document was not successfully moved. Please try again.');
      setNotificationType(NotificationTypeEnum.ERROR);
    }
  };

  const titleUpdateFinished = async () => {
    setIsNoteTitledFocused(false);
    if (noteTitle == null || noteTitle == '') {
      setNoteTitle(noteTitlePlaceholder);
    }
  };

  useEffect(() => {
    //console.log( 'INSIDE MOVING TO FOLDER....');
    //console.log( 'shouldMoveToFolder: ', shouldMoveToFolder);
    //console.log( 'moveToFolderId: ', moveToFolderId);
    if (shouldMoveToFolder && moveToFolderId != null) {
      //console.log( 'moving folder...');
      setShouldMoveToFolder(false);
      setMoveToFolderId(null); // WHY TF IS THIS NULL??????????
      setIsPrivate(false);
      //console.log( 'moveToFolderId: ', moveToFolderId);
      moveNoteToFolder();
    }
  }, [shouldMoveToFolder]);

  function ShouldShareText() {
    const currentFolderName = folders?.find((fold) => fold.id == folderId);
    const filteredFolders = folders?.filter((fold) => fold.id != folderId);
    return (
      <DialogContentText>
        {currentFolderName && (
          <Typography sx={{ fontSize: 18, color: '#404040' }}>
            Current folder: <span style={{ fontWeight: 600 }}>{currentFolderName.name}</span>
          </Typography>
        )}

        <Typography sx={{ paddingTop: 2, paddingBottom: 2, color: '#404040' }}>
          Where would you like to move <span style={{ color: '#1776D1', fontWeight: 600 }}>{noteTitle}</span> document
          to? Select a folder from the list below:
        </Typography>

        {!loadingFolders && filteredFolders && filteredFolders?.length > 0 && (
          <>
            <FoldersCheckList
              folders={filteredFolders}
              moveToFolderId={moveToFolderId}
              setMoveToFolderId={setMoveToFolderId}
            />
            <Typography sx={{ paddingBottom: 2, paddingTop: 2, fontSize: 16, color: '#404040' }}>
              This will allow anyone who has access to this folder to also view/edit this document.
            </Typography>
          </>
        )}

        {!loadingFolders && (folders == undefined || folders == null) && (
          <>You don&apos;t have any folders yet. Start by creating a new folder first.</>
        )}
      </DialogContentText>
    );
  }

  const handleCloseNotification = () => {
    setNotification(false);
    setNotificationText('');
    setNotificationType(NotificationTypeEnum.INFO);
  };

  const viewWidth = '80vw';
  const maxWidthNoteTitle = '70vw';

  return (
    // in case left padding is lost on the header and toolbar bit, then increase the padding below
    <div style={{ backgroundColor: 'white', margin: 0, padding: 1, width: viewWidth }}>
      {notification && (
        <ToastAction
          message={notificationText}
          type={notificationType}
          open={notification}
          close={handleCloseNotification}
        />
      )}

      {/* //TODO: create error component */}
      {hasError && <div style={{ padding: '100px' }}>Sorry, something went wrong. &#x1F61E; Please try again.</div>}

      {openShareAlert && (
        <AlertConfirmation
          text={<ShouldShareText />}
          open={openShareAlert}
          setOpen={setOpenShareAlert}
          status={isPrivate || false}
          confirmationFunction={setShouldMoveToFolder}
          confirmationText="Yes, move"
          canUndoAction={true}
          cancelationText="Cancel"
        />
      )}

      {!loading && !hasError && (
        <div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              backgroundColor: 'white',
              alignItems: 'center',
              justifyContent: 'space-between',
              flexWrap: 'nowrap',
              position: 'fixed',
              top: 0,
              zIndex: 99,
              width: '78vw', // viewWidth
              height: '70px',
              paddingLeft: 2,
              marginLeft: 1,
            }}
          >
            <div
              style={{
                backgroundColor: 'white',
                display: 'flex',
                flexDirection: 'row',
                height: 40,
                verticalAlign: 'middle',
                alignItems: 'center',
                paddingLeft: 10,
              }}
            >
              {!isNoteTitleFocused ? (
                <Typography
                  // noWrap
                  style={{
                    backgroundColor: 'white',
                    display: 'inline-block',
                    verticalAlign: 'middle',
                    alignItems: 'center',
                    fontSize: 20,
                    fontWeight: '600',
                    // paddingRight: 20,
                    width: maxWidthNoteTitle,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                  onClick={handleTitleClick}
                >
                  {noteTitle}
                </Typography>
              ) : (
                <TextField
                  autoFocus
                  variant="standard"
                  inputProps={{
                    style: {
                      fontSize: 20,
                      fontWeight: 'bold',
                      padding: 0,
                      margin: 0,
                      width: maxWidthNoteTitle,
                    },
                  }}
                  value={noteTitle}
                  onChange={(event: any) => setNoteTitle(event.target.value)}
                  onBlur={(event) => titleUpdateFinished()}
                />
              )}
            </div>

            <div
              style={{
                backgroundColor: '',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {isFavourite ? (
                <IconButton
                  id="star-filled-button"
                  disableRipple
                  onClick={() => {
                    setShouldUpdateYdoc(false);
                    handleRemoveFromFavourites();
                  }}
                >
                  <StarFillRoundedIcon sx={{ fontSize: 30, color: '#fdd835', paddingRight: 0 }} />
                </IconButton>
              ) : (
                <IconButton
                  id="star-outlined-button"
                  disableRipple
                  onClick={() => {
                    setShouldUpdateYdoc(false);
                    handleAddToFavourites();
                  }}
                >
                  <StarBorderRoundedIcon sx={{ fontSize: 30, color: '#797979', paddingRight: 0 }} />
                </IconButton>
              )}
              <ShareButton isPrivate={isPrivate || false} tryShare={setShouldTryShare} />
              <NoteBurgerMenu noteId={noteId} folderId={folderId} noteTitle={noteTitle} />
            </div>
          </div>

          <div style={{}}>{!loading && <LexicalEditor ydocUUID={ydocUUID} shouldUpdateYdoc={shouldUpdateYdoc} />}</div>
        </div>
      )}
    </div>
  );
};

export default withAuthenticationRequired(LexicalCodeNote, {
  onRedirecting: () => <Loading />,
});
