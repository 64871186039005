export function writeToErrorLog(error_log: any, errorFlowMessage: string, userId: string | null | undefined) {
  // we cannot write to json/text files from client. Leaving this code to reuse when we onbaord to proper metrics system
  // const errorMessage = {
  //   date: new Date(),
  //   error: 'Error while trying to ' + errorFlowMessage,
  //   error_log,
  //   userId,
  // };
  // try {
  //   fs.appendFileSync('error_logs.json', JSON.stringify(errorMessage) + ', ');
  // } catch (err) {
  //   console.error('Error writing to error_logs.json in client :', err);
  // }
}
