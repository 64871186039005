import * as React from 'react';
import {
  EditorConfig,
  LexicalNode,
  DecoratorNode,
  LexicalEditor,
  NodeKey,
  SerializedLexicalNode,
  Spread,
  $applyNodeReplacement,
} from 'lexical';
import { Suspense } from 'react';
const CodeAnnotationComponent = React.lazy(() => import('./CodeAnnotationComponent'));

const CODE_SNIPPET_TYPE = 'codeAnnotation';

export type SerializedCodeAnnotationNode = Spread<
  {
    githubLink: string | null;
    owner: string | null;
    repo: string | null;
    startLine: number;
    endLine: number;
    documentPath: string | null;
    comment: string;
    initialCommitHash: string | null;
    currentCommitHash: string | null;
    createdAt: string | null;
    updatedAt: string | null;
    type: typeof CODE_SNIPPET_TYPE;
    version: 1;
  },
  SerializedLexicalNode
>;

export class CodeAnnotationNode extends DecoratorNode<JSX.Element> {
  __githubLink: string | null;
  __owner: string | null;
  __repo: string | null;
  __startLine: number;
  __endLine: number;
  __documentPath: string | null;
  __comment: string;
  __initialCommitHash: string | null;
  __currentCommitHash: string | null;
  __createdAt: string | null;
  __updatedAt: string | null;

  static getType(): string {
    return CODE_SNIPPET_TYPE;
  }

  static clone(node: CodeAnnotationNode): CodeAnnotationNode {
    return new CodeAnnotationNode(
      node.__githubLink,
      node.__owner,
      node.__repo,
      node.__startLine,
      node.__endLine,
      node.__documentPath,
      node.__comment,
      node.__initialCommitHash,
      node.__currentCommitHash,
      node.__createdAt,
      node.__updatedAt,
      node.__key
    );
  }

  static importJSON(serializedCodeLinks: SerializedCodeAnnotationNode): CodeAnnotationNode {
    const node = $createCodeAnnotationNode(
      serializedCodeLinks.githubLink,
      serializedCodeLinks.owner,
      serializedCodeLinks.repo,
      serializedCodeLinks.startLine,
      serializedCodeLinks.endLine,
      serializedCodeLinks.documentPath,
      serializedCodeLinks.comment,
      serializedCodeLinks.initialCommitHash,
      serializedCodeLinks.currentCommitHash,
      serializedCodeLinks.createdAt,
      serializedCodeLinks.updatedAt
    );
    return node;
  }

  constructor(
    githubLink: string | null,
    owner: string | null,
    repo: string | null,
    startLine: number,
    endLine: number,
    documentPath: string | null,
    comment: string,
    initialCommitHash: string | null,
    currentCommitHash: string | null,
    createdAt: string | null,
    updatedAt: string | null,
    key?: NodeKey
  ) {
    super(key);
    this.__githubLink = githubLink;
    this.__owner = owner;
    this.__repo = repo;
    this.__startLine = startLine;
    this.__endLine = endLine;
    this.__documentPath = documentPath;
    this.__comment = comment;
    this.__initialCommitHash = initialCommitHash;
    this.__currentCommitHash = currentCommitHash;
    this.__createdAt = createdAt;
    this.__updatedAt = updatedAt;
  }

  createDOM(config: EditorConfig): HTMLElement {
    return document.createElement('span');
  }

  updateDOM(): false {
    return false;
  }

  getComment(): string {
    return this.__comment;
  }

  setComment(comment: string): void {
    const writable = this.getWritable();
    writable.__comment = comment;
  }

  exportJSON(): SerializedCodeAnnotationNode {
    return {
      githubLink: this.__githubLink,
      owner: this.__owner,
      repo: this.__repo,
      startLine: this.__startLine,
      endLine: this.__endLine,
      documentPath: this.__documentPath,
      comment: this.getComment(),
      initialCommitHash: this.__initialCommitHash,
      currentCommitHash: this.__currentCommitHash,
      createdAt: this.__createdAt,
      updatedAt: this.__updatedAt,
      type: CODE_SNIPPET_TYPE,
      version: 1,
    };
  }

  decorate(editor: LexicalEditor, config: EditorConfig): JSX.Element {
    return (
      <Suspense fallback={null}>
        <CodeAnnotationComponent
          format={this.__format}
          nodeKey={this.getKey()}
          githubLink={this.__githubLink}
          owner={this.__owner}
          repo={this.__repo}
          startLine={this.__startLine}
          endLine={this.__endLine}
          documentPath={this.__documentPath}
          comment={this.__comment}
          initialCommitHash={this.__initialCommitHash}
          currentCommitHash={this.__currentCommitHash}
          createdAt={this.__createdAt}
          updatedAt={this.__updatedAt}
        />
      </Suspense>
    );
  }
}

export function $createCodeAnnotationNode(
  githubLink: string | null,
  owner: string | null,
  repo: string | null,
  startLine: number,
  endLine: number,
  documentPath: string | null,
  comment: string,
  initialCommitHash: string | null,
  currentCommitHash: string | null,
  createdAt: string | null,
  updatedAt: string | null
): CodeAnnotationNode {
  const node = new CodeAnnotationNode(
    githubLink,
    owner,
    repo,
    startLine,
    endLine,
    documentPath,
    comment,
    initialCommitHash,
    currentCommitHash,
    createdAt,
    updatedAt
  );
  return $applyNodeReplacement(node);
}

export function $isCodeAnnotationNode(node: LexicalNode): boolean {
  return node instanceof CodeAnnotationNode;
}
