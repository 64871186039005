import React, { useContext, useEffect, useState } from 'react';
import { Box, IconButton, List, ListItem, ListItemIcon, TextField, Typography } from '@mui/material';
import FolderOpenSharpIcon from '@mui/icons-material/FolderOpenSharp';
import FoldersIconMenu from './FoldersIconMenu';
import CreateNewFolderSharpIcon from '@mui/icons-material/CreateNewFolderSharp';
import { AppContext } from 'context/context';
import { Types } from 'context/reducers';
import { HTTP_OK_STATUS_CODE, PRIVATE_FOLDER_TYPE, apiDirLink } from 'sitevars';
import { useAPI } from 'context/ApiProvider';
import { FolderModel } from 'interfaces/interfaces';
import { NotificationTypeEnum, fileTypesEnum } from 'strings';
import { useNavigate } from 'react-router-dom';
import ToastAction from 'components/ToastAction';
import { writeToErrorLog } from 'utils';

const FoldersSidebar = () => {
  const { state, dispatch } = useContext(AppContext);
  const { API } = useAPI();
  const navigate = useNavigate();
  const [folders, setFolders] = useState<FolderModel[]>([]);
  const [loading, setLoading] = useState(true);
  const [hoveredItemIndex, setHoveredItemIndex] = useState(null);
  const [editingItemId, setEditingItemId] = useState(null);
  const [editTitleValue, setEditTitleValue] = useState('');
  const [folderInEdit, setFolderInEdit] = useState<FolderModel | null>(null);
  //notification toast vars
  const [notification, setNotification] = useState<boolean>(false);
  const [notificationText, setNotificationText] = useState<string>('');
  const [notificationType, setNotificationType] = useState<
    typeof NotificationTypeEnum[keyof typeof NotificationTypeEnum]
  >(NotificationTypeEnum.INFO);

  useEffect(() => {
    getFolders();
  }, []);

  const getFolders = async () => {
    const { data } = await API.get(`${apiDirLink}/f`);
    if (data == null) {
      //TODO: log errors; sent an error toast
      return;
    }
    if (data && data.length > 0) {
      setFolders(data);
      setLoading(false);
    }
  };

  const handleItemHover = (index) => {
    setHoveredItemIndex(index);
  };

  const handleItemUnhover = () => {
    setHoveredItemIndex(null);
  };

  const createNewFolder = async () => {
    //console.log( 'inside create new folder');
    const { data } = await API.get(`${apiDirLink}/f/create`);
    setFolders([data, ...folders]); //add new folder at the top of the list of folders
  };

  const handleEditTitleChange = (event) => {
    setEditTitleValue(event.target.value);
  };

  const handleEditTitleSubmit = async (folderId, title, type) => {
    //console.log( `updatingt the title to ${title} for it of ${folderId}`);
    try {
      const updatedTitleName = title != null && title != '' ? title : 'New folder';
      if (updatedTitleName != folderInEdit?.name) {
        const res = await API.post(`${apiDirLink}/f/${folderId}/update`, {
          updates: [
            {
              action: 'title',
              data: updatedTitleName,
            },
          ],
        });

        if (res.status == HTTP_OK_STATUS_CODE && folders != null) {
          const updatedItems = folders.map((item) =>
            item.id === folderId ? { ...item, name: updatedTitleName } : item
          );
          //console.log( 'updateditems:', updatedItems);

          setFolders(updatedItems);
          setEditingItemId(null);
          setEditTitleValue('');
          setFolderInEdit(null);

          // notification for success
          setNotification(true);
          setNotificationText('Folder name is changed!');
          setNotificationType(NotificationTypeEnum.SUCCESS);

          //if title is selected and displayed in Tabs, update the name of the folder in the tab
          if (state.selectedFolderId == folderId) {
            dispatch({
              type: Types.SelectedFolderType,
              payload: {
                selectedFolderId: folderId,
                selectedFolderName: updatedTitleName,
                selectedFolderType: fileTypesEnum.FOLDER,
              },
            });
          }
        }
      } else {
        //todo log that folder name field was clicked to be edited but name was not changed
      }
    } catch (error) {
      // notification for success
      setNotification(true);
      setNotificationText('Folder name was not changed. Please try again.');
      setNotificationType(NotificationTypeEnum.ERROR);
      console.error('Error updating item title:', error);
      writeToErrorLog(error, 'updating item title', state.userEmail);
    }
  };

  const handleKeyDown = (event, id, title, type) => {
    if (event.key === 'Enter') {
      handleEditTitleSubmit(id, title, type);
    }
  };

  const handleOpenFolder = (id, name, type) => {
    dispatch({
      type: Types.SelectedFolderType,
      payload: {
        selectedFolderId: id,
        selectedFolderName: name,
        selectedFolderType: fileTypesEnum.FOLDER,
      },
    });
    navigate(`/folder/${id}`);
  };

  useEffect(() => {
    // Add the event listener when the component mounts
    window.addEventListener('click', handleWindowClick);

    // Remove the event listener when the component unmounts to avoid memory leaks
    return () => {
      window.removeEventListener('click', handleWindowClick);
    };
  }, [editingItemId, editTitleValue]);

  const handleWindowClick = (event) => {
    if (editingItemId !== null) {
      // Check if the click occurred outside the list of items or the TextField
      const isClickOutside =
        !event.target.closest('.list-item-container') && !event.target.closest('input[type="text"]');
      // update title if title name changed and click was outside the folder text edit field
      if (isClickOutside && folderInEdit != null && editTitleValue != folderInEdit.name) {
        handleEditTitleSubmit(editingItemId, editTitleValue, null);
      }
    }
  };

  const handleCloseNotification = () => {
    setNotification(false);
    setNotificationText('');
    setNotificationType(NotificationTypeEnum.INFO);
  };

  return (
    <>
      {notification && (
        <ToastAction
          message={notificationText}
          type={notificationType}
          open={notification}
          close={handleCloseNotification}
        />
      )}

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
          paddingRight: 10,
        }}
      >
        <Typography
          style={{
            paddingTop: 0,
            paddingLeft: 5,
            display: 'flex',
            alignItems: 'center',
            fontSize: 18,
            fontWeight: 'bold',
          }}
        >
          Folders
        </Typography>

        <IconButton id="new-folder-button" onClick={createNewFolder}>
          <CreateNewFolderSharpIcon sx={{ color: '#555555' }} />
        </IconButton>
      </div>
      <List style={{ maxHeight: '73vh', overflow: 'auto' }}>
        {!loading ? (
          folders.map(
            (item, index) =>
              item.type != PRIVATE_FOLDER_TYPE ? (
                <ListItem
                  key={item.id}
                  style={{ padding: 0, margin: 0, backgroundColor: '', cursor: 'pointer', height: '40px' }}
                  onMouseEnter={() => handleItemHover(index)}
                  onMouseLeave={() => handleItemUnhover()}
                >
                  <ListItemIcon
                    style={{
                      padding: 0,
                      margin: 5,
                      marginLeft: 5,
                      backgroundColor: '',
                      minWidth: 25,
                    }}
                    onClick={() => handleOpenFolder(item.id, item.name, item.type)}
                  >
                    {state.selectedFolderId == item.id ? (
                      <FolderOpenSharpIcon sx={{ color: '#0d87e9', fontSize: 20 }} />
                    ) : (
                      <FolderOpenSharpIcon sx={{ color: '#404040', fontSize: 20 }} />
                    )}
                  </ListItemIcon>

                  {editingItemId == item.id ? (
                    <Box>
                      <TextField
                        value={editTitleValue}
                        onChange={handleEditTitleChange}
                        onKeyDown={(event) => handleKeyDown(event, item.id, editTitleValue, item.type)}
                        variant="standard"
                        autoFocus
                        inputProps={{
                          style: { fontWeight: '300', color: '#1976d2' },
                        }}
                      />
                    </Box>
                  ) : (
                    <Box sx={{ maxWidth: 129 }}>
                      <Typography
                        onClick={() => handleOpenFolder(item.id, item.name, item.type)}
                        sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                        style={{ fontWeight: '300' }}
                      >
                        {item.name}
                      </Typography>
                    </Box>
                  )}

                  {hoveredItemIndex === index && (
                    <FoldersIconMenu
                      folder={folders[hoveredItemIndex]}
                      setEditingItemId={setEditingItemId}
                      setEditTitleValue={setEditTitleValue}
                      allFolders={folders}
                      setAllFolders={setFolders}
                      setFolderInEdit={setFolderInEdit}
                    />
                  )}
                </ListItem>
              ) : null // dont want to show the private folder in the list of folders
          )
        ) : (
          <Typography sx={{ paddingLeft: 1, fontWeight: 300, fontSize: 14 }}>No folders yet</Typography>
        )}
      </List>
    </>
  );
};

export default FoldersSidebar;
