import { Typography } from '@mui/material';
import loading from 'assets/loading.svg';

const Loading = ({ loaderText }: { loaderText: string }) => (
  <div
    style={{
      width: '100vw',
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <img src={loading} alt="Loading" />
    <Typography sx={{ paddingTop: 5, fontWeight: 300, fontSize: 20, color: '#0d87e9' }}>{loaderText}</Typography>
  </div>
);

Loading.defaultProps = {
  loaderText: ' Preparing your workspace',
};

export default Loading;
