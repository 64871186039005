import * as React from 'react';
import Button from '@mui/material/Button';
import { Tooltip } from '@mui/material';

export default function ShareButton({ isPrivate, tryShare }: { isPrivate: boolean; tryShare: any }) {
  const handleClick = () => {
    tryShare(true); // This should always be true because we want to change/click on button when is Private and Shared
  };

  return (
    <div>
      {isPrivate ? (
        <Button
          disableElevation
          variant="contained"
          sx={{
            ':hover': {
              bgcolor: 'red',
              color: 'black',
            },
          }}
          style={{
            textTransform: 'none',
            fontWeight: '600',
            paddingTop: '2px',
            paddingBottom: '2px',
            paddingRight: '0px',
            paddingLeft: '0px',
            backgroundColor: '#2196f3',
            color: 'white',
          }}
          onClick={handleClick}
        >
          Private
        </Button>
      ) : (
        <Button
          // disabled
          disableElevation
          variant="contained"
          style={{
            textTransform: 'none',
            fontWeight: '600',
            paddingTop: '2px',
            paddingBottom: '2px',
            paddingRight: '0px',
            paddingLeft: '0px',
            backgroundColor: '#2196f3',
            color: 'white',
          }}
          onClick={handleClick}
        >
          Shared
        </Button>
      )}
    </div>
  );
}
