import React from 'react';
import FeedOutlinedIcon from '@mui/icons-material/FeedOutlined';
import ListItemButton from '@mui/material/ListItemButton';
import { webAppLink, webRoutes } from 'sitevars';
import { Typography } from '@mui/material';

const GettingStartedButton = () => {
  return (
    <>
      <ListItemButton
        id="getting-started-button"
        onClick={() => window.open(`${webAppLink}${webRoutes.gettingStarted}`, '_blank')}
      >
        <FeedOutlinedIcon sx={{ fontSize: 20, color: '#404040', paddingRight: 1 }} />
        <Typography sx={{ fontWeight: 300 }}>Getting started guide</Typography>
      </ListItemButton>
    </>
  );
};

export default GettingStartedButton;
