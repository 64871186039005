import React from 'react';
import PolicyOutlinedIcon from '@mui/icons-material/PolicyOutlined';
import ListItemButton from '@mui/material/ListItemButton';
import { webAppLink, webRoutes } from 'sitevars';
import { Typography } from '@mui/material';

const PrivacyButton = () => {
  return (
    <>
      <ListItemButton id="privacy-button" onClick={() => window.open(`${webAppLink}${webRoutes.security}`, '_blank')}>
        <PolicyOutlinedIcon sx={{ fontSize: 20, color: '#404040', paddingRight: 1 }} />
        <Typography sx={{ fontWeight: 300 }}>Privacy & Security</Typography>
      </ListItemButton>
    </>
  );
};

export default PrivacyButton;
