import * as React from 'react';
import { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from '@mui/icons-material/Menu';
import { apiDirLink, apiNotesLink } from 'sitevars';
import { useNavigate } from 'react-router-dom';
import AlertConfirmation from './AlertConfirmation';
import DialogContentText from '@mui/material/DialogContentText';
import { useAPI } from 'context/ApiProvider';
import { writeToErrorLog } from 'utils';
import { AppContext } from 'context/context';

export default function NoteBurgerMenu({
  noteId,
  folderId,
  noteTitle,
}: {
  noteId: string | null;
  folderId: string | null;
  noteTitle: string | null;
}) {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openDeleteAlert, setOpenDeleteAlert] = useState<boolean>(false);
  const [shouldDeleteFile, setShouldDeleteFile] = useState<boolean>(false);
  const open = Boolean(anchorEl);
  const { API } = useAPI();
  const { state, dispatch } = React.useContext(AppContext);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClone = async () => {
    try {
      const {
        data: { id, folderId },
      } = await API.post(`${apiNotesLink}/n/${noteId}/clone`);
      handleClose();
      window.open(`/f/${folderId}/n/${id}`, '_blank');
    } catch (e) {
      //console.log( 'Error while cloning doc! ', e);
      writeToErrorLog(e, 'cloning from noteId', state.userEmail);
    }
  };

  //TODO: used in 2 places
  const handleNewDocument = async () => {
    try {
      const {
        data: { folderId, id },
      } = await API.post(`${apiNotesLink}/create`);
      handleClose();
      window.open(`/f/${folderId}/n/${id}`, '_blank');
    } catch (e) {
      //console.log( 'Error while creating doc! ', e);
      writeToErrorLog(e, 'creating a new doc', state.userEmail);
    }
  };

  //TODO: used in 2 places
  const deleteNote = async () => {
    if (noteId) {
      try {
        const res = await API.delete(`${apiDirLink}/f/${folderId}/n/${noteId}/delete`);
        //console.log( 'res: ', res);
        navigate('/all');
      } catch (e) {
        //console.log( 'Error while deleting a note! ', e);
        writeToErrorLog(e, 'deleting a noteId', state.userEmail);
      }
    }
  };

  //TODO: used in 2 places
  function ShouldDeleteText() {
    return noteTitle ? (
      <DialogContentText>
        Would you like to delete <strong>{noteTitle}</strong>?
      </DialogContentText>
    ) : (
      <DialogContentText>Would you like to delete this document?</DialogContentText>
    );
  }

  const handleDelete = async (event: any) => {
    event.preventDefault();
    setOpenDeleteAlert(true);
    handleClose();
  };

  return (
    <div>
      {openDeleteAlert && (
        <AlertConfirmation
          text={<ShouldDeleteText />}
          open={openDeleteAlert}
          setOpen={setOpenDeleteAlert}
          status={shouldDeleteFile}
          confirmationFunction={deleteNote}
          confirmationText="Yes, delete"
        />
      )}
      <IconButton
        id="note-icon-menu-button"
        style={{ color: '#2196f3' }}
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <MenuIcon />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={handleNewDocument}>New Document</MenuItem>
        <MenuItem onClick={handleClone}>Clone Document</MenuItem>
        <MenuItem onClick={handleDelete}>Delete Document</MenuItem>
      </Menu>
    </div>
  );
}
