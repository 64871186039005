export const apiBaseLink = process.env.REACT_APP_API as string;
export const yjsWebsocketLink = process.env.REACT_APP_WEBSOCKET as string;
export const webAppLink = process.env.REACT_APP_WEB_APP as string;

export const apiAuthLink = `${apiBaseLink}/auth`;
export const apiNotesLink = `${apiBaseLink}/notes`;
export const apiDirLink = `${apiBaseLink}/dir`;
export const apiGithubLink = `${apiBaseLink}/github`;
export const apiUserLink = `${apiBaseLink}/user`;

export const githubAuthentication = 'https://github.com/apps/codexplain-ai/installations/new';

export const HTTP_OK_STATUS_CODE = 200;

// folder types
export const PRIVATE_FOLDER_TYPE = 'PRIVATE';
export const DEFAULT_FOLDER_TYPE = 'DEFAULT';
export const RECENT_FOLDER_TYPE = 'RECENT'; // this is not a folder type that exists in the db schema. this only exists in the UI

export const auth0Vars = {
  domain: 'dev-qgaafkx4bfzgiwe0.us.auth0.com',
  clientId: 'DSDHCtng7WjLpjYBNz2Y5Gq8mz1gBNmh',
  audience: 'serverIdentifier',
};

export const routes = {
  root: '/',
  codeNotes: '/codeNotes',
  note: '/note',

  //sections
  favourites: '/favourites',
  private: '/private',

  // pages
  login: '/login',
  register: '/register',
  codexplain: '/all',
  logout: '/logout',
};

export const webRoutes = {
  gettingStarted: '/getting-started-guide',
  faq: '/faq',
  security: '/security',
  pricing: '/plans',
};

export const deviceSize = {
  xs: 425,
  sm: 768,
  md: 1024,
  lg: 1440,
};

export const layoutConstant = {
  topbarHeight: 40,
  grocerySidenavWidth: 280,
  containerWidth: 1200,
  mobileNavHeight: 64,
  headerHeight: 80,
  mobileHeaderHeight: 64,
};

export const horizontalStyle = {
  display: 'flex',
  flexDirection: 'row',
  padding: 0,
  margin: 0,
};

export const fullPageStyle = {
  height: '100%',
  position: 'absolute',
  width: '100%',
  overflow: 'hidden',
};

export const themeColors = {
  darkBlue: '#0c0e30',
  cherryRedBright: '#d64444',
  cherryRedDeep: '#a4014e',

  //home squares
  lightYellow: '#f4d96e',
  lightBlue: '#8acee2',
  lightGreen: '#a7c083',
  lightPurple: '#8695bb',
};

export const FULL_LOGO_DARK_THEME = 'https://gotobed.eu/wp-content/codexplain/content/codexplain_full_logo_white.png';
export const FULL_LOGO_LIGHT_THEME = 'https://gotobed.eu/wp-content/codexplain/content/codexplain_full_logo_black.png';
export const MINI_LOGO_DARK_THEME = 'https://gotobed.eu/wp-content/codexplain/content/logo_mini_white.png.png';
export const MINI_LOGO_LIGHT_THEME = 'https://gotobed.eu/wp-content/codexplain/content/logo_mini_dark.png.png';

export const SECTION_ALL_DOCS = 'allFiles';
export const SECTION_FAVOURITES = 'favourites';
export const SECTION_PRIVATE = 'private';
export const SECTION_FOLDER = 'folder';
