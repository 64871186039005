import React from 'react';
import LoginButton from 'pages/account/Login';
import logo from 'assets/codexplain_full_logo_black.png';

const Home = () => {
  return (
    <div
      style={{
        width: '65vw',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'start',
        alignItems: 'center',
        paddingTop: 200,
      }}
    >
      <img src={logo} alt="Logo" className="rotate-left" style={{ width: '300px', paddingBottom: 50 }} />
      <LoginButton text="Get started" />
    </div>
  );
};

export default Home;
